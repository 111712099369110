import React, { useEffect, useState } from "react";
import "./NFT_miniting.css";
import mint from "../Assets/Mint-blue.png";
import mint2 from "../Assets/Mint-green.png";
import mint3 from "../Assets/Mint-purple.png";
import mint4 from "../Assets/Miint-orange.png";
import NFTCard from "./NFTCard";
import Web3 from "web3";

import {
  prepareWriteContract,
  waitForTransaction,
  writeContract,
} from "@wagmi/core";
import {
  mint_Contract_ABI,
  mint_Contract_Address,
  Egg_token_Contract_ABI,
  Egg_token_Contract_Address,
} from "../../Contract/Contract";
import toast from "react-hot-toast";
import { useAccount } from "wagmi";

const nftData = [
  {
    name: "bumblebee Level 1",
    minted: "250",
    price: 0,
    image: mint,
    totalSupply: 0,
  },
  {
    name: "bumblebee Level 2",
    minted: "150",
    price: 0,
    image: mint2,
    totalSupply: 0,
  },
  {
    name: "bumblebee Level 3",
    minted: "100",
    price: 0,
    image: mint3,
    totalSupply: 0,
  },

];

export default function NFT_miniting() {
  const [spinner, setspinner] = useState(Array(nftData.length).fill(false));
  const { address } = useAccount();
  const [cost, setcost] = useState(0);

  const webSupply = new Web3("https://api.mainnetloop.com");

  const Mint = async (index) => {
    try {
      setspinner((prevState) => {
        const newState = [...prevState];
        newState[index] = true;
        return newState;
      });

      let contractOf = new webSupply.eth.Contract(
        mint_Contract_ABI[index],
        mint_Contract_Address[index]
      );
      let mintcost = await contractOf.methods.cost().call();
      const value= mintcost
      console.log("CanClaim", value);
      const { request } = await prepareWriteContract({
        address: Egg_token_Contract_Address,
        abi: Egg_token_Contract_ABI,
        functionName: "approve",
        args: [mint_Contract_Address[index],value.toString()],
        account: address,
      });
      const { hash } = await writeContract(request);
      const data = await waitForTransaction({
        hash,
      });

      setTimeout(async() => {
        toast.success("Approve Transaction Completed");
        const { request } = await prepareWriteContract({
          address: mint_Contract_Address[index],
          abi: mint_Contract_ABI[index],
          functionName: "mint",
          args: [1,value.toString()],
          account: address,
        });
        const { hash } = await writeContract(request);
        const data = await waitForTransaction({
          hash,
        });
        toast.success("Transaction Completed");
        setspinner((prevState) => {
          const newState = [...prevState];
          newState[index] = false;
          return newState;
        });

      }, 4000);
    } catch (error) {
      console.log(error);
      setspinner((prevState) => {
        const newState = [...prevState];
        newState[index] = false;
        return newState;
      });
    }
  };

  const checkcost = async () => {
    for (let i = 0; i < mint_Contract_Address.length; i++) {
      let contractOf = new webSupply.eth.Contract(
        mint_Contract_ABI[i],
        mint_Contract_Address[i]
      );
      let totalSupplyValue = await contractOf.methods.totalSupply().call();
    let mintcost = await contractOf.methods.cost().call();
      // console.log("totalSupplyValue",parseInt(mintcost)/1000000000000000000);
      nftData[i].totalSupply=Number(totalSupplyValue)
      nftData[i].price=parseInt(mintcost)/1000000000000000000

    }
    let contractOf = new webSupply.eth.Contract(
      mint_Contract_ABI[0],
      mint_Contract_Address[0]
    );
    let mintcost = await contractOf.methods.cost().call();
    // console.log("mintcost", mintcost);
    setcost(mintcost);
    // console.log("nftData",nftData);
  };

  useEffect(() => {
    checkcost();
  });
  return (
    <div className="main_nft_minitig">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-10 z_upper">
            <h1 className="mint_he z_upper">Mint your NFT</h1>
            <p className="minitn_para site_pop">
              MINT your NFTs and employ them to amplify your APY.
            </p>
          </div>
          <div className="centered-table">
            <table>
              <thead>
                <tr>
                  <th>Sr no</th>
                  <th>NFT</th>
                  <th>BoostApy</th>
                </tr>
              </thead>
              <tbody>
                {nftData.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td>
                      {index === 0
                        ? "8%"
                        : index === 1
                        ? "20%"
                        : index === 2
                        ? "50%"
                        : "100%"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row justify-content-center my-5">
          <div className="col-md-11">
            <div className="row">
              {nftData.map((item, index) => (
                <NFTCard
                  key={index}
                  index={index}
                  {...item}
                  onclick={Mint}
                  spinner={spinner[index]}
                  totalSupplyValue={item.totalSupply}

                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
