import logo from './logo.svg';
import './App.css';
import Footer from './Components/Footer/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './Components/Header/Header';
import Landing_page from './Components/Landing_page/Landing_page';
import All_staking from './Components/All_staking/All_staking';
import How_mk_works from './Components/How_mk_works/How_mk_works';
import NFT_miniting from './Components/NFT_miniting/NFT_miniting';
import Token_staking from './Components/Token_staking/Token_staking';
import Nft_token_tab from './Components/Nft_token_tab/Nft_token_tab';
import Numbers from './Components/Numbers/Numbers';
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    <div className="App">
      <Toaster />

      <Header />
      <Landing_page />
      <Numbers /> 
      {/* <All_staking /> */}
      <Token_staking />
      <How_mk_works />
      <Footer />
    </div>
  );
}

export default App;
