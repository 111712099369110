import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react'

import { WagmiConfig } from 'wagmi'
import { avalancheFuji } from 'viem/chains'
import { defineChain } from 'viem'

export const LOOP = defineChain({
  id: 15551,
  name: 'LOOP',
  network: 'LOOP',
  nativeCurrency: {
    decimals: 18,
    name: 'LOOP',
    symbol: 'LOOP',
  },
  rpcUrls: {

    default: {
      http: ['https://api.mainnetloop.com'],
      webSocket: ['wss://rpc.zora.energy'],
    },
    public: {
      http: ['https://api.mainnetloop.com'],
      webSocket: ['wss://rpc.zora.energy'],
    },
  },

  blockExplorers: {
    default: { name: 'Explorer', url: 'https://explorer.mainnetloop.com/' },
  },

})

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = 'ac09aec8ba932273ba8fcffbf74619e7'

// 2. Create wagmiConfig
const metadata = {
  name: 'Web3Modal',
  description: 'Web3Modal Example',
  url: 'https://web3modal.com',
  icons: ['https://avatars.githubusercontent.com/u/37784886']
}

const chains = [LOOP]
const wagmiConfig = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
  enableAnalytics: true // Optional - defaults to your Cloud configuration
})

// 3. Create modal
createWeb3Modal({ wagmiConfig, projectId, chains })
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <WagmiConfig config={wagmiConfig}>
      <App />
    </WagmiConfig>
  </React.StrictMode>
);

reportWebVitals();
