import React from "react";

export default function NFTCard({ name, minted, price, image, onclick,spinner,index,totalSupplyValue },props) {
  return (
    <div className="col-md-4 mt-3 mt-md-0 z_upper">
      <div className="mint_nft_card">
        <img src={image} className="mint_img" alt="" />
        <div className="mint_box_content">
          <h4 className="site_pop">{name}</h4>
          <h6 className="site_pop">Minted : {totalSupplyValue}/{minted}</h6>
          <p className="site_pop">
            Price: <span style={{ color: "#646464" }}>{price} Bee tokens</span>
          </p>
          <div className="d-flex justify-content-center">
            <button className="mint_btn_box site_font" onClick={()=>onclick(index)}>
              {spinner ? "Loading..." : "Mint"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
