import React from 'react'
import "./How_mk_works.css"
import hmk1 from "../Assets/hmk1.svg"
import hmk2 from "../Assets/hmk2.svg"
import hmk3 from "../Assets/hmk3.svg"

export default function How_mk_works() {
  return (
    <div className='how_mk_mian'>
    <div className="container">
    <h1 className="hmkw">HOW STAKING $Bee WORKS</h1>
        <div className="row steps_row">
        <div className="col-md-4 lines_sys">
            <div className="how_mk_wo text-center">
                <h6 className='site_pop'>Step 1</h6>
                <img src={hmk1} alt="" />
                <h1>Stake $Bee</h1>
                <p className='site_pop'>Earn daily staking rewards</p>
            </div>
            <div className="side_line d-none d-md-block"></div>
        </div>
        <div className="col-md-4 lines_sys">
            <div className="how_mk_wo text-center">
                <h6 className='site_pop'>Step 2</h6>
                <img src={hmk2} alt="" />
                <h1>Mint NFT</h1>
                <p className='site_pop'>Mint NFT and Stake With Boosted Reward</p>
            </div>
            <div className="side_line d-none d-md-block" style={{background:"#ffffff"}}></div>

        </div>
        <div className="col-md-4 lines_sys">
            <div className="how_mk_wo text-center">
                <h6 className='site_pop'>Step 3</h6>
                <img src={hmk3} alt="" />
                <h1>EARN AND CLAIM REWARD</h1>
                <p className='site_pop'>Claim Your reward at Anytime</p>
            </div>

        </div>

        </div>
    </div>

    </div>
  )
}
