import React, { useState } from "react";
import "./Header.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../Assets/logo.png";
import flag from "../Assets/flag.png";
import { AiOutlineMenu } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import { useAccount, useNetwork, useSwitchNetwork } from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";
// import { Link } from "react-router-dom";

export default function Header() {
  const [show, setShow] = useState(false);
  const { chain } = useNetwork();
  const { chains, switchNetwork } = useSwitchNetwork();
  const { address } = useAccount();
  const { open } = useWeb3Modal();
  const handleClose = () => setShow(false);
  return (
    <div className="main_header_here mb_top_stick">
      <Navbar collapseOnSelect expand="lg" className="mian_nav">
        <Container>
          <Navbar.Brand href="#" className="main_logo">
            {/* <Link to="/"> */} <img src={logo} alt="" />
            {/* </Link> */}
          </Navbar.Brand>
          {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" /> */}
          <button
                  className="header_btn_ora site_pop d-block d-lg-none"
                  onClick={() =>
                    address
                      ? chain?.id == chains[0]?.id
                        ? open()
                        : switchNetwork?.(chains[0]?.id)
                      : open()
                  }
                >
                  {address ? (
                    chain?.id == chains[0]?.id || chain?.id == chains[1]?.id ? (
                      address ? (
                        <>
                          {`${address.substring(0, 6)}...${address.substring(
                            address.length - 4
                          )}`}
                        </>
                      ) : (
                        <>Connect wallet</>
                      )
                    ) : (
                      "Switch"
                    )
                  ) : (
                    <>Connect wallet</>
                  )}{" "}
                </button>
          <span className="d-block d-lg-none" onClick={() => setShow(!show)}>
            {show ? (
              <>
                <RxCross2 className="text-dark fs-1" />{" "}
              </>
            ) : (
              <>
                <AiOutlineMenu className="text-dark fs-1" />
              </>
            )}
          </span>
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className={show ? "show" : ""}
          >
            <Nav className="me-auto">
              <Nav.Link
                className="apy"
                href="#features"
                onClick={handleClose}
                style={{ color: "#AA00B8" }}
              >
                Home
              </Nav.Link>


{/*               
              <Nav.Link href="#Howitwork" onClick={handleClose}>
                Token Contract
              </Nav.Link>
              <Nav.Link href="#Tokenomics" onClick={handleClose}>
                Stake
              </Nav.Link>
              
              <Nav.Link href="#pricing" onClick={handleClose}>
                Mint Nft
              </Nav.Link> */}
             
            </Nav>
            <Nav className="ms-auto">
              <div className="d-flex flex-column flex-lg-row gap-2 align-items-center">
                <button
                  className="outline_btn_ora site_pop"
                  onClick={handleClose}
                >
                  Mint NFT
                </button>
                {/* <button
                  className="header_btn_ora site_pop"
                  onClick={handleClose}
                >
                  How to Buy
                </button> */}
                <button
                  className="header_btn_ora d-none d-lg-block site_pop"
                  onClick={() =>
                    address
                      ? chain?.id == chains[0]?.id
                        ? open()
                        : switchNetwork?.(chains[0]?.id)
                      : open()
                  }
                >
                  {address ? (
                    chain?.id == chains[0]?.id || chain?.id == chains[1]?.id ? (
                      address ? (
                        <>
                          {`${address.substring(0, 6)}...${address.substring(
                            address.length - 4
                          )}`}
                        </>
                      ) : (
                        <>Connect wallet</>
                      )
                    ) : (
                      "Switch"
                    )
                  ) : (
                    <>Connect wallet</>
                  )}{" "}
                </button>
                {/* <NavDropdown
                  className=""
                  title={
                    <span>
                      {" "}
                      <img src={flag} alt="" /> EN
                    </span>
                  }
                  id="collapsible-nav-dropdown"
                >

                </NavDropdown> */}
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}
