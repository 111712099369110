import React, { useEffect, useState } from "react";
import "./Nft_token_tab.css";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import NFT_miniting from "../NFT_miniting/NFT_miniting";
import calim from "../Assets/claim_card.png";
import stake from "../Assets/totalsection.png";
import refresh from "../Assets/refresh.png";
import withd from "../Assets/withdraw.png";
import level from "../Assets/level.png";
import level2 from "../Assets/level2.svg";
import Web3 from "web3";

import {
  prepareWriteContract,
  waitForTransaction,
  writeContract,
} from "@wagmi/core";
import {
  mint_Contract_ABI,
  mint_Contract_Address,
  Egg_token_Contract_ABI,
  Egg_token_Contract_Address,
  Egg_token_Staking_Contract_ABI,
  Egg_token_Staking_Contract_Address,
  Egg_NFT_Staking_Contract_Address,
  Egg_NFT_Staking_Contract_ABI,
} from "../../Contract/Contract";
import toast from "react-hot-toast";
import { useAccount } from "wagmi";
import NFTStaking from "./NFTStaking";
import moment from "moment";
import Countdown from "react-countdown";

const levelMap = [
  {
    level: 1,
    nft: 3,
    mintbalance: 0,
  },
  {
    level: 2,
    nft: 3,
    mintbalance: 0,
  },
  {
    level: 3,
    nft: 3,
    mintbalance: 0,
  },
];

export default function NFTStakings({ daysMap, value }) {
  const { address } = useAccount();
  const [loackableDays, setloackableDays] = useState(30);
  const [selectlevel, setSelectlevel] = useState(1);
  const [getinputValue, setGetinputValue] = useState("");
  const [stakeSpinner, setStakeSpinner] = useState(false);
  const [Stake_History_show, setStake_History_show] = useState([]);
  const [showpendindRewards, setShowPendindRewards] = useState(0);
  const [spinnerClaim, setspinnerClaim] = useState(false);
  const [totalStaked, settotalStaked] = useState(0);
  const [tokenbalance, settokenbalance] = useState(0);
  const [unStakeSpinner, setunStakeSpinner] = useState("");
  const [apy, setapy] = useState("8");



  const webSupply = new Web3("https://api.mainnetloop.com");
  const checkapy = async () => {

    console.log(selectlevel,"selected level");

  if(selectlevel==1){
    setapy("8 %")
  }
  else if(selectlevel==2){
    setapy("20 %")
  }
  else if(selectlevel==3){
    setapy("50 %")
  }
  else if(selectlevel==4){
    setapy("100 %")
  }


  };

  const checkmintbalance = async () => {


    if (address) {
      for (let i = 0; i < mint_Contract_Address.length; i++) {
        let contractOf = new webSupply.eth.Contract(
          mint_Contract_ABI[i],
          mint_Contract_Address[i]
        );
        let balanceOf = await contractOf.methods.balanceOf(address).call();
        levelMap[i].mintbalance=Number(balanceOf)
      }
      let contractOftoken = new webSupply.eth.Contract(
        Egg_token_Contract_ABI,
        Egg_token_Contract_Address
      );
      let tokenbalance = await contractOftoken.methods
        .balanceOf(address)
        .call();
      // tokenbalance=webSupply.utils.fromWei(tokenbalance.toString());
      tokenbalance = Number(tokenbalance) / Number(1000000000000000000);
      settokenbalance(tokenbalance);
      let contractOfStaking = new webSupply.eth.Contract(
        Egg_NFT_Staking_Contract_ABI,
        Egg_NFT_Staking_Contract_Address
      );
      let pendindRewards = await contractOfStaking.methods
        .pendindRewards(address)
        .call();
      pendindRewards = Number(pendindRewards) / Number(1000000000000000000);
    //   let valueToken =
    //   Number(pendindRewards) / Number(1000000000000000000);

    // let amounts = valueToken.toLocaleString("fullwide", {
    //   useGrouping: false,
    // });
    // let amounts1 = parseInt(amounts);
    // pendindRewards = amounts1.toLocaleString("fullwide", {
    //   useGrouping: false,
    // });
console.log(pendindRewards,"pendindRewards");
      setShowPendindRewards(pendindRewards);

      let Users = await contractOfStaking.methods.Users(address).call();
      Users = Number(Users[0]) / Number(1000000000000000000);
      settotalStaked(Users);
      let contractOf = new webSupply.eth.Contract(
        mint_Contract_ABI[0],
        mint_Contract_Address[0]
      );




    }
  };





  const stakeNFT = async () => {
    try {

      if (address) {
        setStakeSpinner(true);
        let contractOf = new webSupply.eth.Contract(
          mint_Contract_ABI[selectlevel - 1],
          mint_Contract_Address[selectlevel - 1]
        );
        let walletOfOwner = await contractOf.methods
          .walletOfOwner(address)
          .call();

        console.log("walletOfOwner", walletOfOwner[0]);
        if (walletOfOwner?.length !== 0) {
          if (getinputValue !== "") {
            // let valueToken = webSupply.utils.toWei(getinputValue.toString());
            // let valueToken = Web3.utils.toWei(getinputValue.toString())

            let valueToken =
              Number(getinputValue) * Number(1000000000000000000);

            let amounts = valueToken.toLocaleString("fullwide", {
              useGrouping: false,
            });
            let amounts1 = parseInt(amounts);
            valueToken = amounts1.toLocaleString("fullwide", {
              useGrouping: false,
            });

            console.log(valueToken, "valueToken");
            const { request } = await prepareWriteContract({
              address: Egg_token_Contract_Address,
              abi: Egg_token_Contract_ABI,
              functionName: "approve",
              args: [Egg_NFT_Staking_Contract_Address, valueToken.toString()],
              account: address,
            });
            const { hash } = await writeContract(request);
            const data = await waitForTransaction({
              hash,
            });
            toast.success("Transaction Completed");
            const approveAll = await prepareWriteContract({
              address: mint_Contract_Address[selectlevel - 1],
              abi: mint_Contract_ABI[selectlevel - 1],
              functionName: "setApprovalForAll",
              args: [Egg_NFT_Staking_Contract_Address, true],
              account: address,
            });
            const approveHash = await writeContract(approveAll?.request);
            const approvedata = await waitForTransaction({
              hash: approveHash.hash,
            });
            toast.success("Transaction Completed");

            setTimeout(async () => {
              setStakeSpinner(true);
              const { request } = await prepareWriteContract({
                address: Egg_NFT_Staking_Contract_Address,
                abi: Egg_NFT_Staking_Contract_ABI,
                functionName: "farm",
                args: [
                  valueToken.toString(),
                  loackableDays,
                  Number(walletOfOwner[0]),
                  mint_Contract_Address[selectlevel - 1],
                ],
                account: address,
              });
              const { hash } = await writeContract(request);
              const data = await waitForTransaction({
                hash,
              });
              toast.success("Transaction Completed");
              setStakeSpinner(false);
            }, 3000);
          } else {
            toast.error("Please enter Token value First!");
            setStakeSpinner(false);
          }
        } else {
          toast.error("Sorry! You have no NFT");
          setStakeSpinner(false);
        }
      } else {
        toast.error("Connect Wallet First!");
        setStakeSpinner(false);
      }
    } catch (error) {
      setStakeSpinner(false);
      console.log(error);
    }
  };


  const Stake_History = async () => {
    try {
      let stakingContractOf = new webSupply.eth.Contract(
        Egg_NFT_Staking_Contract_ABI,
        Egg_NFT_Staking_Contract_Address
      );

      if (address) {
        let History_obj = {};
        let UserInformation = await stakingContractOf.methods
          .userInformation(address)
          .call();
        let array1 = UserInformation[0];
        let array2 = UserInformation[1];
        let array3 = UserInformation[2];
        let array4 = UserInformation[3];
        let array5 = UserInformation[5];

        // console.log("UserInformation", array4);
        // console.log("levels", array5);


        let myArray = [];

        for (let i = 0; i < array1?.length; i++) {
          // let date =new Date(Number(array3[i])*1000).toUTCString();
          let currentTimestamp = Number(array3[i]);

          let amount = Number(array1[i]) / Number(1000000000000000000);

          let date = moment(Number(array3[i]) * 1000).format("DD-MM-YYYY");
          let obj = {
            Sno: i + 1,
            address: address,
            amount: amount,
            unLoackTime:
              Number(currentTimestamp) + Number(86400) * Number(array2[i]),
            LockTime: date,
            userAddress: array4[i],
            levels:array5[i]
          };
          myArray = [...myArray, obj];
        }
        setStake_History_show(myArray);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    checkmintbalance();
    Stake_History();
    checkapy()
  }, [value]);



  const Completionist = () => {
    return (
      <>
        <div className="text_days fs-5 ">Unstaked Time Reached!</div>
      </>
    );
  };

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist />;
    } else {
      return (
        <div className="text_days fs-5 ">
          {/* {days} D {hours} H {minutes} M {seconds} S */}
          {days}d : {hours}h : {minutes}m : {seconds}s
        </div>
      );
    }
  };

  const harvest = async (index, Useraddress) => {
    try {
      if (address) {
        const { request } = await prepareWriteContract({
          address: Egg_NFT_Staking_Contract_Address,
          abi: Egg_NFT_Staking_Contract_ABI,
          functionName: "harvest",
          args: [[index], Useraddress],
          account: address,
        });
        const { hash } = await writeContract(request);
        const data = await waitForTransaction({
          hash,
        });
        toast.success("Transaction Completed");
        setunStakeSpinner("");
      } else {
        toast.error("Connect Wallet First!");
        setunStakeSpinner("");
      }
    } catch (error) {
      console.log(error);
      setunStakeSpinner("");
    }
  };

  const claimToken = async (index) => {
    try {
      if (address) {
        setspinnerClaim(true);
        const { request } = await prepareWriteContract({
          address: Egg_NFT_Staking_Contract_Address,
          abi: Egg_NFT_Staking_Contract_ABI,
          functionName: "claim",
          args: [],
          account: address,
        });
        const { hash } = await writeContract(request);
        const data = await waitForTransaction({
          hash,
        });
        toast.success("Transaction Completed");
        setspinnerClaim(false);
      } else {
        toast.error("Connect Wallet First!");
        setspinnerClaim(false);
      }
    } catch (error) {
      console.log(error);
      setspinnerClaim(false);
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12 z_upper">
          <h1 className="tkn_head ">Stake $Bee Token + NFT</h1>
          <p className="tkn_para site_pop mt-2">
            Your wallet: {parseFloat(tokenbalance).toFixed(2)} $Bee
          </p>
        </div>
      </div>
      <p className="tkn_para site_pop">Choose Your Plan</p>
      <div className="row my-5 justify-content-center">
        <div className="col-md-12">
          <div className="main_apy_box d-flex justify-content-center g-2">
            {daysMap?.map((items, index) => {
              return (
                <>
                  <div
                    key={index}
                    className={
                      items.days == loackableDays
                        ? "inner_apy_box me-2 z_upper activeInner"
                        : "inner_apy_box me-2 z_upper"
                    }
                    onClick={() => setloackableDays(items.days)}
                  >
                    <h1>{items.daysmonth} Days</h1>
                    <div
                      className={
                        items.days == loackableDays ? "activebort" : "bort "
                      }
                    >
                      <p className="apy_rate">{items.apy}% apy</p>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 z_upper">
          {/* <h1 className="wallet_nf">Your Wallet NFT Balance</h1> */}
          <p className="tkn_para site_pop">Choose Your NFT To Boost APY</p>
        </div>
      </div>
      <div className="row my-5 justify-content-center ">
        <div className="col-md-12">
          <div className="main_apy_boxxxx d-flex justify-content-center g-2">
            {levelMap.map((items, index) => {
              return (
                <>
                  <div
                    className="inner_lever z_upper me-5"
                    onClick={() => setSelectlevel(items.level)}
                  >
                    <div className="d-flex align-items-center gap-1">
                      {selectlevel == items.level ? (
                        <>
                          <img
                            src={level}
                            className="d-none d-md-block"
                            alt="level"
                            width="22%"
                          />
                        </>
                      ) : (
                        <>
                          <img
                            src={level2}
                            className="d-none d-md-block"
                            alt="level2"
                          />
                        </>
                      )}
                      <span className="level_num site_pop">
                        Level {items.level} = {items.mintbalance} NFt
                      </span>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>

      <div className="text-white">
        <p
          className=" wallet_nf position-relative z_upper site_pop text-center text-dark mt-2"
          style={{ color: "black !important", fontSize: "1.2rem" }}
        >
          Your reward is Boosted with {apy} Apy
        </p>
      </div>

      <div className="row justify-content-center">
        <div className="col-md-6 z_upper">
          <div className="stk_amt">
            <p className="site_font text-dark">Stake amount</p>
            <div className="amnt_main d-flex justify-content-between">
              <input
                type="text"
                className="stk_amnt_in site_font"
                placeholder="0"
                name=""
                id=""
                value={getinputValue}
                onChange={(e) => setGetinputValue(e.target.value)}
              />
              <button
                className="max_btn site_font"
                onClick={() =>
                  tokenbalance == 0
                    ? setGetinputValue(0)
                    : setGetinputValue(
                        parseFloat(
                          Number(tokenbalance) - Number(0.001)
                        ).toFixed(2)
                      )
                }
              >
                Max
              </button>
            </div>
            <p className="site_font text-dark">Enter amount of tokens to stake</p>

            <div className="row justify-content-center mt-4">
              <div className="col-md-12 z_upper">
                <button
                  className="lower_stake_btn site_font"
                  onClick={stakeNFT}
                >
                  {stakeSpinner ? "Loading..." : "Stake"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row my-5">
        <div className="col-md-6 z_upper">
          <div className="claim_card">
            <div className="d-flex justify-content-between align-items-center">
              <div className="content_claim_box">
                <h1>CLAIM</h1>
                <p className="site_font">Claim your staking rewards</p>
              </div>
              <div>
                {/* <img src={calim} className="card_claim_pic" alt="" /> */}
              </div>
            </div>
            <div className="row  mt-3 mt-md-0">
              <div className="col-md-9">
                <button className="claim_card_btn site_font">
                  Unclaimed rewards: {parseFloat(showpendindRewards).toFixed(4)} $xBee
                </button>
                <div className="d-flex gap-2 mt-2">
                  <button className="claim_card_btn_outlined site_font">
                    Learn more
                  </button>
                  <button
                    className="claim_card_btn site_font"
                    onClick={claimToken}
                  >
                    {spinnerClaim ? "Loading..." : "Claim now"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 z_upper mt-3 mt-md-0">
          <div className="claim_card">
            <div className="d-flex justify-content-between align-items-center">
              <div className="content_claim_box">
                <h1>Total Stake</h1>
                <p className="site_font">
                  Claim any previously purchased tokens here.
                </p>
              </div>
              <div>
                {/* <img src={stake} className="card_claim_pic" alt="" /> */}
              </div>
            </div>
            <div className="row mt-3 mt-md-0">
              <div className="col-md-9">
                <button className="claim_card_btn site_font">
                  Total Staked: {parseFloat(totalStaked).toFixed(4)} $Bee
                </button>
                <div className="d-flex gap-2 mt-2">
                  <button className="claim_card_btn_outlined site_font">
                    Learn more
                  </button>
                  {/* <button className="claim_card_btn site_font">Unstake</button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row align-items-center  mt-4 justify-content-between">
        <div className="col-6 z_upper ">
          <h1 className="tskea">Your Stakes</h1>
        </div>
        <div className="col-6 z_upper text-end ">
          <div>
            <button className="refres " onClick={Stake_History}>
              {" "}
              Refresh <img src={refresh} alt="" />
            </button>
          </div>
        </div>
      </div>
      <div className="row my-5  ">
        <div className="table-responsive z_upper">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">Sr.no #</th>
                <th scope="col">Staked Amount</th>
                <th scope="col">NFT Level</th>

                <th scope="col">
                  Withdraw Date &Time <img src={withd} alt="" />
                </th>
                <th scope="col">Unstake</th>
              </tr>
            </thead>
            <tbody>
              {Stake_History_show.length == 0 ? (
                <>
                  <tr>
                    <td
                      className="MuiTableCell-root MuiTableCell-body MuiTableCell-sizeMedium css-q34dxg"
                      colSpan={5}
                      style={{ border: "none" }}
                    >
                      <div className="MuiBox-root css-ehd0rl">
                        <p className="MuiTypography-root MuiTypography-body1 css-o7q7an">
                          You have no staking data
                        </p>
                      </div>
                    </td>{" "}
                  </tr>
                  {/* <td style={{color:"red"}} className="total_ree">Total reward</td> */}
                </>
              ) : (
                <>
                  {Stake_History_show.map((items, index) => {
                    let current_Time = Math.floor(
                      new Date().getTime() / 1000.0
                    );

                    return (
                      <>
                        {items.unstaked == true || items.withdrawan == true ? (
                          <></>
                        ) : (
                          <>
                            <tr className="MuiTableRow-root css-1gqug66">
                              <td
                                className="MuiTableCell-root MuiTableCell-body MuiTableCell-sizeMedium css-q34dxg  text-center"
                                scope="col"
                              >
                                {items.Sno}
                              </td>
                              <td
                                className="MuiTableCell-root MuiTableCell-body MuiTableCell-sizeMedium css-q34dxg  text-center"
                                scope="col"
                              >
                                {items.amount}
                              </td>
                              <td
                                className="MuiTableCell-root MuiTableCell-body MuiTableCell-sizeMedium css-q34dxg  text-center"
                                scope="col"
                              >
                                {items.levels}
                              </td>
                              <td
                                className="MuiTableCell-root MuiTableCell-body MuiTableCell-sizeMedium css-q34dxg  text-center"
                                scope="col"
                              >
                                <Countdown
                                  date={
                                    Date.now() +
                                    (parseInt(items.unLoackTime) * 1000 -
                                      Date.now())
                                  }
                                  renderer={renderer}
                                />
                              </td>
                              <td
                                className="MuiTableCell-root MuiTableCell-body MuiTableCell-sizeMedium css-q34dxg  text-center"
                                scope="col"
                              >
                                <button
                                  className="refres"
                                  tabIndex={0}
                                  type="button"
                                  onClick={() =>
                                    current_Time >= items.unLoackTime
                                      ? (harvest(index, items.userAddress),
                                        setunStakeSpinner(index))
                                      : toast.error("Unstake time not reached!")
                                  }
                                >
                                  {unStakeSpinner === index
                                    ? "Loading ..."
                                    : "Unstake"}

                                  <span className="MuiTouchRipple-root css-w0pj6f" />
                                </button>
                              </td>{" "}
                            </tr>{" "}
                          </>
                        )}
                      </>
                    );
                  })}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
